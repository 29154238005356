import { useAuth } from "oidc-react";

export const NoAccess: React.FC = () => {
  const auth = useAuth();
  return (
    <div className="bg-neutral_0 overflow-hidden shadow rounded-lg h-full flex flex-col justify-center">
      <div
        data-testid="login-form"
        className="px-4 py-5 w-40 m-auto text-center"
      >
        <p>No access</p>
        <p>You are not logged in.</p>
        <p>Please log in to other account or contact support.</p>
        <div className="menu-item px-5">
          <button onClick={() => auth.signIn()} className="menu-link px-5">
            Log in
          </button>
        </div>
      </div>
    </div>
  );
};
