import { AppRoutes } from "@router/routes";
import { useAuth } from "oidc-react";

import { useNavigate } from "react-router-dom";

const useSignOut = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const clearStorageData = () => {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.startsWith("oidc.user:https://sts.anchor-ball")) {
        sessionStorage.removeItem(key);
      }
    });

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("oidc.user:https://sts.anchor-ball")) {
        localStorage.removeItem(key);
      }
    });
  };

  const onSignOut = () => {
    auth.signOut();
    clearStorageData();
    navigate(AppRoutes.NO_ACCESS);
  };

  return onSignOut;
};

export { useSignOut };
