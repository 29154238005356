import { FC } from "react";

import { getLayoutFromLocalStorage } from "../../layout/core";
import icons from "../icons-config/icons";

type Props = {
  className?: string;
  iconType?: "duotone" | "solid" | "outline";
  iconName: string;
};

const KTIcon: FC<Props> = ({ className = "", iconType, iconName }) => {
  const actualIconType = !iconType ? getLayoutFromLocalStorage().main?.iconType : iconType;

  return (
    <i className={`ki-${actualIconType} ki-${iconName}${className && " " + className}`}>
      {actualIconType === "duotone" &&
        [...Array(icons[iconName])].map((_e, i) => {
          return (
            <span
              key={`${actualIconType}-${iconName}-${className}-path-${i + 1}`}
              className={`path${i + 1}`}
            ></span>
          );
        })}
    </i>
  );
};

export { KTIcon };
