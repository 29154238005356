import { Route, Routes as RoutesSwitch } from "react-router-dom";

import { NoAccess } from "./NoAccess";

export const UnauthenticatedApp = () => {
  return (
    <RoutesSwitch>
      <Route path="/no-access" element={<NoAccess />} />
      <Route path="*" element={<NoAccess />} />
    </RoutesSwitch>
  );
};
