import { useAuth } from "oidc-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AppRoutes } from "./routes";

const ReAuthorization = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.signOut().then(() => {
      auth.signIn().then(() => {
        navigate(AppRoutes.IAM);
      });
    });
  });

  return null;
};

export default ReAuthorization;
