import { MutableRefObject, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { ToggleComponent } from "../../../assets/ts/components";
import { useLayout } from "../../core";

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>;
};

const SidebarLogo = (props: PropsType) => {
  const { config } = useLayout();
  const toggleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      const toggleObj = toggleRef.current
        ? (ToggleComponent.getInstance(toggleRef.current) as ToggleComponent)
        : null;

      if (toggleObj === null) {
        return;
      }

      // Add a class to prevent sidebar hover effect after toggle click
      toggleObj.on("kt.toggle.change", function () {
        // Set animation state
        props.sidebarRef.current?.classList.add("animating");

        // Wait till animation finishes
        setTimeout(function () {
          // Remove animation state
          props.sidebarRef.current?.classList.remove("animating");
        }, 300);
      });
    }, 600);
  }, [toggleRef, props.sidebarRef]);

  return (
    <div className="app-sidebar-logo px-6" id="kt_app_sidebar_logo">
      <Link to="/">
        {config.layoutType === "dark-sidebar" ? (
          <span
            className="fs-2x h-40px app-sidebar-logo-default text-white"
            style={{ whiteSpace: "nowrap" }}
          >
            Librarian
          </span>
        ) : (
          <>
            <span
              className="fs-2x h-40px app-sidebar-logo-default theme-light-show text-white"
              style={{ whiteSpace: "nowrap" }}
            >
              Librarian
            </span>
            <span
              className="fs-2x h-40px app-sidebar-logo-default theme-dark-show text-white"
              style={{ whiteSpace: "nowrap" }}
            >
              Librarian
            </span>
          </>
        )}
        <span
          className="fs-2x h-40px app-sidebar-logo-default app-sidebar-logo-minimize"
          style={{ whiteSpace: "nowrap" }}
        >
          📚
        </span>
      </Link>
    </div>
  );
};

export { SidebarLogo };
