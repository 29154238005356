import { MetronicI18nProvider } from "@_metronic/i18n/Metronici18n";
import { I18nProvider } from "@_metronic/i18n/i18nProvider";
import { MasterInit } from "@_metronic/layout/MasterInit";
import { LayoutProvider, LayoutSplashScreen } from "@_metronic/layout/core";
import { ThemeModeProvider } from "@_metronic/partials";
import AuthCheckpoint from "@router/AuthCheckpoint";
import AppRouter from "@router/router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthProvider } from "oidc-react";
import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { OpenAPI as CuratorOpenAPI } from "@web/curator";
import { OpenAPI as IamOpenAPI } from "@web/iam";
import { OpenAPI as SopimusOpenAPI } from "@web/sopimus";
import { OpenAPI as WarehouseOpenAPI } from "@web/warehouse";

import "./_metronic/assets/fonticon/fonticon.css";
import "./_metronic/assets/keenicons/duotone/style.css";
import "./_metronic/assets/keenicons/outline/style.css";
import "./_metronic/assets/keenicons/solid/style.css";
import "./_metronic/assets/sass/style.react.scss";

/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/style.scss";

const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;

CuratorOpenAPI.BASE = apiBaseUrl;
CuratorOpenAPI.ENCODE_PATH = encodeURIComponent;
IamOpenAPI.BASE = apiBaseUrl;
IamOpenAPI.ENCODE_PATH = encodeURIComponent;
SopimusOpenAPI.BASE = apiBaseUrl;
SopimusOpenAPI.ENCODE_PATH = encodeURIComponent;
WarehouseOpenAPI.BASE = apiBaseUrl;
WarehouseOpenAPI.ENCODE_PATH = encodeURIComponent;

const oidcConfig = {
  onSignIn: async () => {
    window.location.hash = "/";
  },
  authority: `${import.meta.env.VITE_STS_DOMAIN}`,
  clientId: `${import.meta.env.VITE_STS_CLIENT_ID}`,
  redirectUri: window.location.origin,
};

const queryClient = new QueryClient();
const container = document.getElementById("root");

if (container) {
  createRoot(container).render(
    <AuthProvider {...oidcConfig}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <MetronicI18nProvider>
            <AuthCheckpoint>
              <Suspense fallback={<LayoutSplashScreen />}>
                <I18nProvider>
                  <LayoutProvider>
                    <ThemeModeProvider>
                      <MasterInit />
                      <AppRouter />
                    </ThemeModeProvider>
                  </LayoutProvider>
                </I18nProvider>
              </Suspense>
            </AuthCheckpoint>
          </MetronicI18nProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}
