export enum AppRoutes {
  ROOT = "/",

  ERROR = "/error",

  NO_ACCESS = "/no-access",

  RE_AUTHORIZE = "/oauth2/authorization/oidc",

  IAM = "/iam",

  IAM_ME = "/iam/me",

  IAM_ACCOUNTS = "/iam/accounts",
  IAM_ACCOUNTS_CREATE = "/iam/accounts/create",
  IAM_ACCOUNT_OAUTH2_ADD = "/iam/accounts/:accountId/oauth2/add",

  IAM_CLIENTS = "/iam/clients",
  IAM_CLIENTS_CREATE = "/iam/clients/create",
  IAM_CLIENT_POLICIES = "/iam/clients/:clientId/policies",
  IAM_CLIENT_POLICIES_ATTACH_POLICY = "/iam/clients/:clientId/policies/attach-policy",
  IAM_CLIENT_POLICIES_ATTACH_INLINE_POLICY = "/iam/clients/:clientId/policies/attach-inline-policy",

  IAM_ROLES = "/iam/roles",
  IAM_ROLES_CREATE = "/iam/roles/create",
  IAM_ROLE_TRUST_RELATIONSHIP = "/iam/roles/:roleId/trust-relationship",
  IAM_ROLE_PRINCIPALS = "/iam/roles/:roleId/principals",
  IAM_ROLE_PRINCIPALS_ATTACH_PRINCIPAL = "/iam/roles/:roleId/principals/attach-principal",
  IAM_ROLE_PRINCIPALS_ATTACH_SIGNUP_CODE = "/iam/roles/:roleId/principals/attach-signup-code",
  IAM_ROLE_POLICIES = "/iam/roles/:roleId/policies",
  IAM_ROLE_POLICIES_ATTACH_POLICY = "/iam/roles/:roleId/policies/attach-policy",

  IAM_POLICIES = "/iam/policies",
  IAM_POLICIES_CREATE = "/iam/policies/create",

  SOPIMUS = "/sopimus",
  SOPIMUS_CONTRACTS = "/sopimus/contracts",
  SOPIMUS_CONTRACTS_CREATE = "/sopimus/contracts/create",
  SOPIMUS_CONTRACT_TARGETS = "/sopimus/contracts/:contractId/targets",
  SOPIMUS_CONTRACT_TARGETS_ADD = "/sopimus/contracts/:contractId/targets/add",
  SOPIMUS_CONTRACT_EDIT = "/sopimus/contracts/:contractId/edit",
  SOPIMUS_CONTRACT_PRICE_AGREEMENTS = "/sopimus/contracts/:contractId/price-agreements",
  SOPIMUS_CONTRACT_PRICE_AGREEMENTS_ATTACH = "/sopimus/contracts/:contractId/price-agreements/attach",
  SOPIMUS_CONTRACT_PRICE_AGREEMENT_EDIT = "/sopimus/contracts/:contractId/price-agreements/:agreementId/edit",
  SOPIMUS_CONTRACT_HISTORY = "/sopimus/contracts/:contractId/history",

  CURATOR = "/curator",
  CURATOR_CATALOGS = "/curator/catalogs",
  CURATOR_CATALOG_EDIT = "/curator/catalogs/:catalogId/edit",
  CURATOR_CATALOG_HISTORY = "/curator/catalogs/:catalogId/history",
  CURATOR_CATALOGS_CREATE = "/curator/catalogs/create",
  CURATOR_CATALOG_CATEGORY_TREE = "/curator/catalogs/:catalogId/category-trees",
  CURATOR_CATALOG_CATEGORY_TREE_CREATE = "/curator/catalogs/:catalogId/category-trees/create",
  CURATOR_CATALOG_BUYERS = "/curator/catalogs/:catalogId/buyers",
  CURATOR_CATALOG_BUYERS_CREATE = "/curator/catalogs/:catalogId/buyers/create",

  WAREHOUSE = "/warehouse",
  WAREHOUSE_CATALOGS = "/warehouse/catalogs",
  WAREHOUSE_CATALOGS_HISTORY = "/warehouse/catalogs/:catalogId/history",
  WAREHOUSE_CATALOGS_CREATE = "/warehouse/catalogs/create",
}
