import { toAbsoluteUrl } from "@_metronic/helpers";
import { useSignOut } from "@hooks/useSignOut";
import { AppRoutes } from "@router/routes";
import { useAuth } from "oidc-react";
import { FC } from "react";

const HeaderUserMenu: FC = () => {
  const auth = useAuth();
  const signOut = useSignOut();

  const assumeRoleLink = `${import.meta.env.VITE_STS_DOMAIN}/assume-role?redirectUrl=http://${
    window.location.host
  }${AppRoutes.RE_AUTHORIZE}&role=srn:iam:535136:role/Source2Sea-Support`;

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-375px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={toAbsoluteUrl("media/avatars/blank.png")} />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {(auth.userData?.profile.an as string) || ""}
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {auth.userData?.profile.email ?? ""}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <a href={assumeRoleLink} className="menu-link px-5">
          Assume Source2Sea-Support role
        </a>
      </div>

      <div className="menu-item px-5">
        <a onClick={signOut} className="menu-link px-5">
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
