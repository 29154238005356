import { getCSSVariableValue } from "@_metronic/assets/ts/_utils";
import { WithChildren } from "@_metronic/helpers";
import { MasterLayout } from "@_metronic/layout/MasterLayout";
import { ErrorsPage } from "@pages/Errors/ErrorsPage";
import { Error404 } from "@pages/Errors/components/Error404";
import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

import ReAuthorization from "./ReAuthorization";
import { AppRoutes } from "./routes";

const AppRouter: FC = () => {
  const IAMPage = lazy(() => import("@pages/IAM"));
  const SopimusPage = lazy(() => import("@pages/Sopimus"));
  const WarehousePage = lazy(() => import("@pages/Warehouse"));
  const CuratorPage = lazy(() => import("@pages/Curator"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route index element={<Navigate to={AppRoutes.IAM} />} />
        <Route
          path={`${AppRoutes.IAM}/*`}
          element={
            <SuspensedView>
              <IAMPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${AppRoutes.SOPIMUS}/*`}
          element={
            <SuspensedView>
              <SopimusPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${AppRoutes.WAREHOUSE}/*`}
          element={
            <SuspensedView>
              <WarehousePage />
            </SuspensedView>
          }
        />
        <Route
          path={`${AppRoutes.CURATOR}/*`}
          element={
            <SuspensedView>
              <CuratorPage />
            </SuspensedView>
          }
        />

        <Route
          path={`${AppRoutes.RE_AUTHORIZE}/*`}
          element={
            <SuspensedView>
              <ReAuthorization />
            </SuspensedView>
          }
        />
      </Route>

      <Route path={`${AppRoutes.ERROR}/*`} element={<ErrorsPage />} />
      <Route path={`${AppRoutes.ROOT}*`} element={<Error404 />} />
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default AppRouter;
